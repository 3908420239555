import React from "react";
import { OutboundLink } from "gatsby-plugin-google-analytics";
import { FaGlobe } from "@react-icons/all-files/fa/FaGlobe";

import Head from "../../components/head.js";

import Layout from "../../components/layout";

const Rosalia = () => {
  return (
    <Layout>
      <Head title="Rosalía" />
      <h3>Rosalía</h3>
      <h4>
        <FaGlobe style={{ marginRight: 7, paddingTop: 5 }} />
        Catalonia, Spain
      </h4>
      <p>
        <OutboundLink href="https://www.rosalia.com/">Website</OutboundLink>
      </p>
      <p>
        <OutboundLink href="https://www.instagram.com/rosalia.vt/">
          Instagram
        </OutboundLink>
      </p>
      <p>
        Known mononymously as Rosalía (Spanish: [rosaˈlia] or Catalan:
        [ruzə'liə]), is a Spanish singer and songwriter. After discovering
        Spanish folk music at an early age, Rosalía graduated from Catalonia
        College of Music with honors by virtue of her collaborative cover album
        with Raül Refree, Los Ángeles (2017), and the baccalaureate project El
        Mal Querer (2018), which is co-produced by El Guincho and reimagines
        flamenco by mixing it with pop and urban music. The latter spawned the
        single "Malamente", which caught the attention of the Spanish general
        public, and was released to universal critical acclaim. Recipient of the
        Latin Grammy Award for Album of the Year and listed in Rolling Stone's
        500 Greatest Albums of All Time, El Mal Querer started the ascent of
        Rosalía into the international music scene
      </p>

      <div className="video-container">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/y28_bM8h_Cg"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
      <div className="video-container">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/Y2ESxHKuSOk"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
      <div className="video-container">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/e-CEd6xrRQc"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
      <div className="video-container">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/6o7bCAZSxsg"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>

      <div className="video-container">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/H3iIBdkPTYs"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
      <div className="video-container">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/3k1Y2MQ1ihg"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
    </Layout>
  );
};

export default Rosalia;
